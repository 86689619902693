import { EssVeeGee } from "./components/structures";
import MyHealthDirectOrangeMarker from "./svg/MyHealthDirectOrangeMarker";
import NcontractsBlueN from "./svg/NcontractsBlueN";

const millisecondsInYear = 31536000000;
// todo: https://tc39.es/proposal-temporal/docs/index.html ?
const xp = +((Date.now() - new Date(2016, 6)) / millisecondsInYear).toFixed(1)

export const me = {
	name: "me",
	currentRole: "refactory",
	yearsCoding: xp,
	devCareer: [
		"ncontracts",
		"mhd"
	],
	otherCareers: [
		"pharmacy",
		"air force"
	],
	meElsewhere: [
		"github",
		"mastodon",
		"twitter",
		"linkedin"
	],
	getMeta: propertyName => {
		return metaMe[propertyName]
	}
}


export const metaMe = {
	name: {
		value: "Martin Cross",
		icon: "🤞",
		type: "string"
	},
	currentRole: {
		value: "Looking for work",
		icon: "🧑‍💻",
		type: "string",
	},
	yearsCoding: {
		value: xp,
		icon: xp === 6.9
		? "🍆🌮😛 😉"
		: xp % 1 === 0
			? xp % 1 > 0.7
				? "💪"
				: "🎉"
			: null,
		type: "number", // yes this is cheating
	},
	devCareer: [
		{
			value: "Ncontracts",
			icon: <EssVeeGee><NcontractsBlueN/></EssVeeGee>,
			type: "string",
			url: "https://www.ncontracts.com/"
		},
		{
			value: "MyHealthDirect",
			icon: <EssVeeGee><MyHealthDirectOrangeMarker/></EssVeeGee>,
			type: "string",
			url: "https://www.myhealthdirect.com"
		}
	],
	otherCareers: [
		{
			value: "Pharmacy Benefit Compliance",
			icon: "💊",
			type: "string",
			url: "https://www.medicare.gov/drug-coverage-part-d"
		},
		{
			value: "Aeromedical Evacuation Technician",
			icon: "🤕",
			type: "string",
			url: "https://www.af.mil/About-Us/Fact-Sheets/Display/Article/490683/air-mobility-command-aeromedical-evacuation/"
		}
	],
	meElsewhere: [
		{
			value: "GitHub",
			icon: "🐙😸",
			type: "string",
			url: "https://github.com/franticallycodes"
		},
		{
			value: "Instagram",
			icon: "📸",
			type: "string",
			url: "https://www.instagram.com/franticallycodes"
		},
		{
			value: "Mastodon",
			icon: "🐘",
			type: "string",
			url: "https://hachyderm.io/@franticallycodes"
		},
		{
			value: "Twitter",
			icon: "🆇",
			type: "string",
			url: "https://twitter.com/franticallycode"
		},
		{
			value: "LinkedIn",
			icon: "🔗",
			type: "string",
			url: "https://www.linkedin.com/in/martin-cross-56998680/"
		},
	],
	default: {
		value: "whoopsie",
		icon: "🤷‍♂️",
		type: "string",
		url: "https://youtu.be/mmNhfW3pG-8"
	}
}
